<template>
    <v-layout align-start>
        <v-flex>
            <v-data-table
            :headers="headers"
            :items  ="tarifas"
            :search = "search"
            class   ="elevation-1"
            >
                <template v-slot:top>
                    <v-toolbar flat >
                        <v-toolbar-title>Tarifas</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                    <!-- barra de busqueda    -->
                        <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Búsqueda" single-line hide-details>
                        </v-text-field>

                        <v-spacer></v-spacer>

                    <!-- modal NUEVO o ACTUALIZAR tarifa  -->
                        <template v-if="isAdmin">
                            <v-dialog v-model="dialog" max-width="500px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="greenA" dark class="mb-2" v-bind="attrs" v-on="on" raised rounded>
                                        Nueva
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        <span class="text-h5">{{ formTitle }}</span>
                                        <v-spacer></v-spacer>
                                        <v-btn icon @click="dialog = false">
                                            <v-icon>close</v-icon>
                                        </v-btn>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-text-field v-model="nombre" label="Nombre">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="6">
                                                    <v-text-field v-model="desde" label="Desde" type="number">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="6">
                                                    <v-text-field v-model="hasta" label="Hasta" type="number">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="6" >
                                                    <v-text-field v-model="valor" label="Valor" type="number">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12" v-show="valid">
                                                    <div class="red--text"
                                                        v-for="v in validMessage" :key="v" v-text="v">
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                    
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blueA" text @click="close" >
                                            Cancelar
                                        </v-btn>
                                        <v-btn color="greenA" text @click="save" >
                                            Guardar
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </template>

                        <!-- modal Activar/desactivar tarifa  -->
                        <v-dialog v-model="dialogActDeact" max-width="500px">
                            <v-card>
                                <v-card-title >
                                    <template v-if="adAction==false">
                                    <v-spacer></v-spacer>
                                    ¿Estás seguro de Activar {{adNombre}}?
                                    <v-spacer></v-spacer>
                                    </template>
                                    <template v-if="adAction==true">
                                    <v-spacer></v-spacer>
                                    ¿Estás seguro de Desactivar {{adNombre}}?
                                    <v-spacer></v-spacer>
                                    </template>
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blueA" text @click="closeDelete">
                                        Cancelar
                                    </v-btn>
                                    
                                    <v-btn color="greenA" text @click="ActionActDeact(adAction)">
                                        Aceptar
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                    </v-toolbar>
                </template>

                <template v-slot:item.estado="props">
                    <td>
                        <div v-if="props.item.estado">
                            <span class="greenA--text">Activa</span>
                        </div>
                        <div v-else>
                            <span class="pinkA--text">Inactiva</span>
                        </div>
                    </td>
                </template>

            <!-- start opciones Editar - activar/desactivar  -->
                <template v-slot:item.opciones="{ item }" v-if="isAdmin">
                    <v-btn color="blueA" @click="editItem(item)" class="mr-2 mt-1" fab dark small title="Editar tarifa">
                       <v-icon color="white" >edit</v-icon>
                    </v-btn>
                     <template v-if="item.estado">
                    <v-btn color="pinkA" @click="activateDeactivate(false,item)" class="mr-2 mt-2 mb-1" fab dark small title="Desactivar tarifa">
                       <v-icon color="white" >block</v-icon>
                    </v-btn>
                    </template>
                    <template v-else>
                    <v-btn color="greenA" @click="activateDeactivate(true,item)" class="mr-2 mt-2 mb-1" fab dark small title="Activar tarifa">
                       <v-icon color="white" >check</v-icon>
                    </v-btn>
                    </template>
                </template>
            <!-- end opciones Editar - activar/desactivar  -->
            
                <template v-slot:no-data>
                    <v-btn color="blueA" @click="listTarifas()" dark>
                    Recargar listado
                    </v-btn>
                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
</template>


<script>
import Vue from 'vue';
import axios from 'axios';
import VueAlertify from 'vue-alertify';
Vue.use(VueAlertify);

export default {
    data(){
        return {
            dialog: false,
            search: '',
            tarifas: [],
            headers: [
                { text: 'Nombre',       value:  'nombre',   sortable:true   },
                { text: 'Desde (km)',   value:  'desde',    sortable:false  },
                { text: 'Hasta (km)',   value:  'hasta',    sortable:false  },
                { text: 'Valor (COP)',  value:  'valor',    sortable:true   },
                { text: 'Estado',       value:  'estado',   sortable:false  },
                { text: 'Opciones',     value:  'opciones', sortable:false  },
            ],
            editedIndex: -1,
            _id:'',
            nombre:'',
            desde:0,
            hasta:0,
            valor:0,
            valid:0,
            validMessage:[],
            dialogActDeact:false,
            adAction:false,
            dialogDelete:false,
            adNombre:'',
            adId:'',
            token: this.$store.state.token,
        }
    },

    computed: {
        formTitle () {
        return this.editedIndex === -1 ? 'Crear tarifa' : 'Editar tarifa'
        },
        isAdmin(){
        return this.$store.state.nombre && this.$store.state.nombre.rol == 'Administrador';
        },
        isRegular(){
        return this.$store.state.nombre && this.$store.state.nombre.rol == 'Usuario Regular';
        },
    },

    watch: {
        dialog (val) {
        val || this.close()
        },
        dialogActDeact (val) {
        val || this.closeDelete()
        },
        dialogDelete (val) {
        val || this.closeDelete()
        },
    },

    created () {
        this.listTarifas()
    },

    methods: {
        success(mensaje) {
        this.$alertify.success(mensaje);
        },
        
        alert(mensaje) {    
            this.$alertify.warning(mensaje);      
        },

        validate(){
            this.valid          = 0;
            this.validMessage   = [];
            if ( this.nombre.length<3 || this.nombre.length>50 ){
                this.validMessage.push('Por favor, el nombre ');
            }
            if ( this.desde.length==0 ){
                this.validMessage.push('Por favor, revisa desde.');
            }
            if ( this.hasta.length==0 ){
                this.validMessage.push('Por favor, revisa hasta.');
            }
            if ( this.valor.length<2 ){
                this.validMessage.push('Por favor, revisa el valor.');
            }
            if ( this.validMessage.length){
                this.valid = 1;
            }
            return this.valid;                           
        },

        listTarifas(){
            let me = this;
            let headers = {headers: {token: this.token}};
            axios.get('api/tarifa/listforadmin', headers)
            .then(function(response){
                me.tarifas = response.data.tarifas;
            })
            .catch(function(error){
                me.alert('Sin servicio, intente mas tarde');
            })
        },

        clear(){
            this._id          ='';
            this.nombre       ='';
            this.desde        =0;
            this.hasta        =0;
            this.valor        =0;
            this.valid        =0;
            this.validMessage =[];
            this.editedIndex  =-1;
            this.adNombre     ='';
            this.adId         ='';
        },

        async save () {
            let me = this;
            if( this.validate()){
                return;
            }

            if (this.editedIndex > -1) {
                let headers = {headers: {token: this.token}};
                // Editar tarifa
                axios.put('api/tarifa/update',{
                    '_id'   :this._id,
                    'nombre':this.nombre,
                    'desde' :this.desde,
                    'hasta' :this.hasta,
                    'valor' :this.valor,
                    }, headers)
                    .then(function (response) {
                        me.clear();
                        me.close();
                        me.listTarifas();
                        me.success('se editó el tarifa ');
                    })
                    .catch(function (error) {
                        me.clear();
                        me.close();
                        me.listTarifas();
                        me.alert('No se pudo actualizar la tarifa');
                    })
            } else {
                let headers = {headers: {token: this.token}};
                // Guardar tarifa
                axios.post('api/tarifa/add',{
                    'nombre':this.nombre,
                    'desde' :this.desde,
                    'hasta' :this.hasta,
                    'valor' :this.valor,
                    }, headers)
                    .then(function (response) {
                        me.clear();
                        me.close();
                        me.listTarifas();
                        me.success('se creó nueva tarifa');
                    })
                    .catch(function (error) {
                        console.log(error);
                        me.clear();
                        me.close();
                        me.listTarifas();
                        me.alert('No se pudo registrar tarifa, verifique que no exista en la base de datos');
                    })
            }
        },

        editItem (item) {
            this._id        = item._id;
            this.nombre     = item.nombre;     
            this.desde      = item.desde;
            this.hasta      = item.hasta;
            this.valor      = item.valor;
            this.dialog     = true;
            this.editedIndex= 1;
        },

        activateDeactivate (action, item) {
            this.dialogActDeact = true;
            this.adNombre       = item.nombre;
            this.adId           = item._id;
            if (action==true){
                this.adAction = false;
            }else if (action==false){
                this.adAction = true;
            }else{
                this.dialogActDeact = false;
            }
        },

        ActionActDeact (actDeact) {
            let me = this;
            if (actDeact){
                let headers = {headers: {token: this.token}};
                // Desactivar tarifa
                axios.put('api/tarifa/deactivate?_id='+this.adId, null, headers)
                    .then(function (response) {
                        me.clear();
                        me.close();
                        me.listTarifas();
                        me.success('se desactivo el tarifa ');
                    })
                    .catch(function (error) {
                        me.clear();
                        me.close();
                        me.listTarifas();
                        me.alert('No se pudo desactivar la tarifa, por favor intente mas tarde');
                    })
            }else{
                let headers = {headers: {token: this.token}};
                // Activar tarifa
                axios.put('api/tarifa/activate?_id='+this.adId, null, headers)
                    .then(function (response) {
                        me.clear();
                        me.close();
                        me.listTarifas();
                        me.success('se activo el tarifa ');
                    })
                    .catch(function (error) {
                        me.clear();
                        me.close();
                        me.listTarifas();
                        me.alert('No se pudo activar la tarifa, por favor intente mas tarde');
                    })                
            }
        },

        close () {
            this.clear();
            this.dialog = false;
            this.dialogActDeact = false;
            this.editedIndex = -1
        },

        closeDelete () {
            this.dialogActDeact = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
    },
}
</script>