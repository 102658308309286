import Vue              from 'vue'
import VueRouter        from 'vue-router'
import store            from '../store/index' 
import Domiciliario     from '../components/Domiciliario.vue'
import Cliente          from '../components/Cliente.vue'
import Pedido           from '../components/EnviosDiligencias.vue'
import PedidoCancelado  from '../components/ReprogramarEnviosDiligencias.vue'
import PedidoFinalizado from '../components/HistorialEnviosDiligencias.vue'
import Login            from '../components/Login.vue'
import Home             from '../components/Home.vue'
import Empresa          from '../components/Empresa.vue'
import Usuario          from '../components/Usuario.vue'
import Tarifa           from '../components/Tarifas'
import Servicio         from '../components/TipoServicios'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      administrador:true,
      regular:true
    }
  },
  {
    path: '/',
    name: 'empresa',
    component: Empresa,
    meta: {
      administrador:true,
    }
  },
  {
    path: '/',
    name: 'usuario',
    component: Usuario,
    meta: {
      administrador:true,
    }
  },
  {
    path: '/',
    name: 'domiciliario',
    component: Domiciliario,
    meta: {
      administrador:true,
      regular:true
    }
  },
  {
    path: '/',
    name: 'cliente',
    component: Cliente,
    meta: {
      administrador:true,
      regular:true
    }
  },
  {
    path: '/',
    name: 'pedido',
    component: Pedido,
    meta: {
      administrador:true,
      regular:true
    }
  },
  {
    path: '/',
    name: 'pedidocancelado',
    component: PedidoCancelado,
    meta: {
      administrador:true,
      regular:true
    }
  },
  {
    path: '/',
    name: 'pedidofinalizado',
    component: PedidoFinalizado,
    meta: {
      administrador:true,
      regular:true
    }
  },
  {
    path: '/',
    name: 'tarifa',
    component: Tarifa,
    meta: {
      administrador:true,
      regular:true
    }
  },
    {
    path: '/',
    name: 'servicio',
    component: Servicio,
    meta: {
      administrador:true,
      regular:true
    }
  },
  {
    path: '/',
    name: 'login',
    component: Login,
    meta: {free:true}
  },
  
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.free)){
    next();
  }else if(store.state.nombre && store.state.nombre.rol == 'Administrador'){
    if (to.matched.some(record => record.meta.administrador)){
      next();
    }
  }else if(store.state.nombre && store.state.nombre.rol == 'Usuario Regular'){
    if (to.matched.some(record => record.meta.regular)){
      next();
    }
  }else{
    next({name: 'login'});
  }
})

export default router
