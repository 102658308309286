import Vue     from 'vue'
import App     from './App.vue'
import router  from './router'
import store   from './store'
import vuetify from './plugins/vuetify'
import axios   from 'axios';
import 'leaflet/dist/leaflet.css';

Vue.config.productionTip = false
Vue.prototype.$baseUrl = 'https://apiacelerar.ideasappcolombia.com/'  //url para imagenes
//axios.defaults.baseURL = 'http://localhost:3000/'
axios.defaults.baseURL = 'https://apiacelerar.ideasappcolombia.com/'

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
