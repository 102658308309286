<template>
    <v-layout align-start>
        <v-flex>
            <v-data-table
            :headers="headers"
            :items  ="servicios"
            :search = "search"
            class   ="elevation-1"
            >
                <template v-slot:top>
                    <v-toolbar flat >
                        <v-toolbar-title>Tipos de Servicios</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                    <!-- barra de busqueda    -->
                        <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Búsqueda" single-line hide-details>
                        </v-text-field>

                        <v-spacer></v-spacer>

                    <!-- modal ACTUALIZAR servicio  -->
                        <template v-if="isAdmin">
                            <v-dialog v-model="dialog" max-width="500px">

                                <v-card>
                                    <v-card-title>
                                        <span class="text-h5">Actualizar servicio</span>
                                        <v-spacer></v-spacer>
                                        <v-btn icon @click="dialog = false">
                                            <v-icon>close</v-icon>
                                        </v-btn>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-text-field v-model="nombre" label="Nombre">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-select v-model="ida_y_vuelta"
                                                    :items="selec_ida_y_vuelta" label="Ida y Vuelta">
                                                    
                                                    </v-select>
                                                </v-col>
                                                <!-- imagen descriptiva      -->
                                                <v-layout align-center justify-center>
                                                    <v-card  elevation="24" outlined>
                                                        <v-spacer></v-spacer>
                                                        <div class="home">
                                                            <img alt="Vue logo" v-bind:src="imagen" width="250px">
                                                        </div>
                                                        
                                                    <v-card-title align-center justify-center>
                                                        <v-spacer></v-spacer>
                                                            <input @change="selectImage($event)" type="file" accept="image/*">
                                                        <v-spacer></v-spacer>
                                                    </v-card-title>
                                                    <v-spacer></v-spacer>
                                                    </v-card>
                                                </v-layout> 
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                    
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blueA" text @click="close" >
                                            Cancelar
                                        </v-btn>
                                        <v-btn color="greenA" text @click="edit" >
                                            Guardar
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </template>

                    <!-- modal NUEVO servicio  -->
                        <template v-if="isAdmin">
                            <v-dialog v-model="dialogNew" max-width="500px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="greenA" dark class="mb-2" v-bind="attrs" v-on="on" raised rounded>
                                        Nuevo
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        <span class="text-h5">Crear servicio</span>
                                        <v-spacer></v-spacer>
                                        <v-btn icon @click="dialogNew = false">
                                            <v-icon>close</v-icon>
                                        </v-btn>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-text-field v-model="nombre" label="Nombre">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-select v-model="ida_y_vuelta"
                                                    :items="selec_ida_y_vuelta" label="Ida y Vuelta">
                                                    
                                                    </v-select>
                                                </v-col>
                                                <!-- imagen descriptiva      -->
                                                <v-layout align-center justify-center>
                                                    <v-card  elevation="24" outlined>
                                                        <div class="home">
                                                            <input @change="selectImage($event)" type="file" accept="image/*">
                                                        </div>
                                                    </v-card>
                                                </v-layout> 
                                                <v-col cols="12" sm="12" md="12" v-show="valid">
                                                    <div class="red--text"
                                                        v-for="v in validMessage" :key="v" v-text="v">
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                    
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blueA" text @click="close" >
                                            Cancelar
                                        </v-btn>
                                        <v-btn color="greenA" text @click="save" >
                                            Guardar
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </template>

                        <!-- modal Activar/desactivar tarifa  -->
                        <v-dialog v-model="dialogActDeact" max-width="500px">
                            <v-card>
                                <v-card-title >
                                    <template v-if="adAction==false">
                                    <v-spacer></v-spacer>
                                    ¿Estás seguro de Activar {{adNombre}}?
                                    <v-spacer></v-spacer>
                                    </template>
                                    <template v-if="adAction==true">
                                    <v-spacer></v-spacer>
                                    ¿Estás seguro de Desactivar {{adNombre}}?
                                    <v-spacer></v-spacer>
                                    </template>
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blueA" text @click="closeDelete">
                                        Cancelar
                                    </v-btn>
                                    
                                    <v-btn color="greenA" text @click="ActionActDeact(adAction)">
                                        Aceptar
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                    </v-toolbar>
                </template>

                <template v-slot:item.estado="props">
                    <td>
                        <div v-if="props.item.estado">
                            <span class="greenA--text">Activo</span>
                        </div>
                        <div v-else>
                            <span class="pinkA--text">Inactivo</span>
                        </div>
                    </td>
                </template>

            <!-- start opciones Editar - activar/desactivar  -->
                <template v-slot:item.opciones="{ item }" v-if="isAdmin">
                    <v-btn color="blueA" @click="editItem(item)" class="mr-2 mt-1" fab dark small title="Editar servicio">
                       <v-icon color="white" >edit</v-icon>
                    </v-btn>
                     <template v-if="item.estado">
                    <v-btn color="pinkA" @click="activateDeactivate(false,item)" class="mr-2 mt-2 mb-1" fab dark small title="Desactivar servicio">
                       <v-icon color="white" >block</v-icon>
                    </v-btn>
                    </template>
                    <template v-else>
                    <v-btn color="greenA" @click="activateDeactivate(true,item)" class="mr-2 mt-2 mb-1" fab dark small title="Activar servicio">
                       <v-icon color="white" >check</v-icon>
                    </v-btn>
                    </template>
                </template>
            <!-- end opciones Editar - activar/desactivar  -->
            
                <template v-slot:no-data>
                    <v-btn color="blueA" @click="listServicios()" dark>
                    Recargar listado
                    </v-btn>
                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
</template>


<script>
import Vue from 'vue';
import axios from 'axios';
import VueAlertify from 'vue-alertify';
Vue.use(VueAlertify);

export default {
    data(){
        return {
            dialog: false,
            dialogActDeact:false,
            adAction:false,
            dialogDelete:false,
            dialogNew:false,
            search: '',
            servicios: [],
            fichero:null,
            headers: [
                { text: 'Nombre',       value:  'nombre',       sortable:true   },
                { text: 'Ida y vuelta', value:  'ida_y_vuelta', sortable:false  },
                { text: 'Estado',       value:  'estado',       sortable:false  },
                { text: 'Opciones',     value:  'opciones',     sortable:false  },
            ],
            editedIndex: -1,
            _id:'',
            nombre:'',
            ida_y_vuelta:'',
            selec_ida_y_vuelta:['si', 'no'],
            imagen:'',
            nameImage:'',
            valid:0,
            validMessage:[],
            adNombre:'',
            adId:'',
            token: this.$store.state.token,
        }
    },

    computed: {
        isAdmin(){
        return this.$store.state.nombre && this.$store.state.nombre.rol == 'Administrador';
        },
        isRegular(){
        return this.$store.state.nombre && this.$store.state.nombre.rol == 'Usuario Regular';
        },
    },

    watch: {
        dialog (val) {
        val || this.close()
        },
        dialogActDeact (val) {
        val || this.closeDelete()
        },
        dialogDelete (val) {
        val || this.closeDelete()
        },
        dialogNew (val) {
        val || this.closeDelete()
        },
    },

    created () {
        this.listServicios()
    },

    methods: {
        selectImage(e){
            var imagenUp = e.target.files[0];
            const allowedTypes =["image/jpg", "image/jpeg", "image/png"];
            if (imagenUp.type==undefined) {
                this.alert('No seleccionaste ninguna imagen');
            }else{
                if (allowedTypes.includes(imagenUp.type)) {
                this.fichero = imagenUp;
                this.nameImage = imagenUp.name.split('.').slice(0,-1).toString();
            } else {
                this.alert('Imagen en formato no valido, verifique que sea .jpg o .png');
            }
            }
            
        },

        success(mensaje) {
            this.$alertify.success(mensaje);
        },
        
        alert(mensaje) {    
            this.$alertify.warning(mensaje);      
        },

        validate(){
            this.valid          = 0;
            this.validMessage   = [];
            if ( this.nombre.length<3 || this.nombre.length>50 ){
                this.validMessage.push('Por favor, el nombre ');
            }
            if ( this.validMessage.length){
                this.valid = 1;
            }
            return this.valid;                           
        },

        listServicios(){
            let me = this;
            let headers = {headers: {token: this.token}};
            axios.get('api/tiposervicios/listforadmin', headers)
            .then(function(response){
                me.servicios = response.data.tipoServicios;
            })
            .catch(function(error){
                me.alert('Sin servicio, intente mas tarde');
            })
        },

        clear(){
            this._id          ='';
            this.nombre       ='';
            this.ida_y_vuelta ='';
            this.imagen       ='';
            this.valid        =0;
            this.validMessage =[];
            this.editedIndex  =-1;
            this.adNombre     ='';
            this.adId         ='';
        },

        async save () {
            let me = this;
            let headers = {headers: {token: this.token, 'Content-Type':'multipart/form-data'}};
            const formdata = new FormData();
            formdata.append('imagen', me.fichero);
            await axios.post('api/tiposervicios/saveimage',formdata, headers).then(resp=>{}).catch(function(error){console.log(error);})            
            // Guardar tarifa
            await axios.post('api/tiposervicios/add',{
                'nombre':me.nombre,
                'ida_y_vuelta' :me.ida_y_vuelta,
                'imagen' : me.nameImage+'.webp',
                }, {headers: {token: this.token}})
                .then(function (response) {
                    me.clear();
                    me.close();
                    me.listServicios();
                    me.success('se creó nuevo servicio');
                })
                .catch(function (error) {
                    console.log(error);
                    me.clear();
                    me.close();
                    me.listServicios();
                    me.alert('No se pudo registrar servicio, verifique que no exista en la base de datos');
                })
        },

        async edit(){
            let me = this;
            if( this.validate()){
                return;
            }        
            if (me.nameImage!='') {
                let headers = {headers: {token: this.token, 'Content-Type':'multipart/form-data'}};
                const formdata = new FormData();
                formdata.append('imagen', me.fichero);
                await axios.post('api/tiposervicios/saveimage',formdata, headers).then(resp=>{
                         axios.put('api/tiposervicios/update',{
                        '_id':me._id,
                        'imagen' : me.nameImage+'.webp',
                        }, {headers: {token: this.token}})
                        .then(function (response) {
                            me.success('se editó imagen servicio');
                        })
                        .catch(function (error) {
                            console.log(error);
                            me.alert('No se pudo editar imagen');
                        })
                }).catch(function(error){console.log(error);})   
            } 
            // Guardar tarifa
            await axios.put('api/tiposervicios/update',{
                '_id':me._id,
                'nombre':me.nombre,
                'ida_y_vuelta' :me.ida_y_vuelta,
                }, {headers: {token: this.token}})
                .then(function (response) {
                    me.clear();
                    me.close();
                    me.listServicios();
                    me.success('se editó nuevo servicio');
                })
                .catch(function (error) {
                    console.log(error);
                    me.clear();
                    me.close();
                    me.listServicios();
                    me.alert('No se pudo editar servicio, verifique que no exista en la base de datos');
                })
        },

        editItem (item) {
            this._id            = item._id;
            this.nombre         = item.nombre;     
            this.ida_y_vuelta   = item.ida_y_vuelta;
            this.imagen         = this.$baseUrl+item.imagen;
            this.dialog         = true;
            this.editedIndex    = 1;
        },

        activateDeactivate (action, item) {
            this.dialogActDeact = true;
            this.adNombre       = item.nombre;
            this.adId           = item._id;
            if (action==true){
                this.adAction = false;
            }else if (action==false){
                this.adAction = true;
            }else{
                this.dialogActDeact = false;
            }
        },

        ActionActDeact (actDeact) {
            let me = this;
            if (actDeact){
                let headers = {headers: {token: this.token}};
                // Desactivar tarifa
                axios.put('api/tiposervicios/deactivate?_id='+this.adId, null, headers)
                    .then(function (response) {
                        me.clear();
                        me.close();
                        me.listServicios();
                        me.success('se desactivo el servicio ');
                    })
                    .catch(function (error) {
                        me.clear();
                        me.close();
                        me.listServicios();
                        me.alert('No se pudo desactivar el servicio, por favor intente mas tarde');
                    })
            }else{
                let headers = {headers: {token: this.token}};
                // Activar servicio
                axios.put('api/tiposervicios/activate?_id='+this.adId, null, headers)
                    .then(function (response) {
                        me.clear();
                        me.close();
                        me.listServicios();
                        me.success('se activo el servicio ');
                    })
                    .catch(function (error) {
                        me.clear();
                        me.close();
                        me.listServicios();
                        me.alert('No se pudo activar el servicio, por favor intente mas tarde');
                    })                
            }
        },

        close () {
            this.clear();
            this.dialog = false;
            this.dialogActDeact = false;
            this.dialogNew = false;
            this.editedIndex = -1
        },

        closeDelete () {
            this.dialogActDeact = false;
            this.dialogNew = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
    },
}
</script>