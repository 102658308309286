<template>
    <v-layout align-start>
        <v-flex>
            <v-data-table
            :headers="headers"
            :items  ="pedidos"
            :search = "search"
            class   ="elevation-1"
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>Envios y diligencias por reasignar</v-toolbar-title>

                        <v-divider class="mx-4" inset vertical></v-divider>

                        <v-spacer></v-spacer>

                    <!-- start barra de busqueda    -->
                        <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Búsqueda" single-line hide-details>
                        </v-text-field>
                    <!-- end barra de busqueda    -->

                        <v-spacer></v-spacer>

                    <!-- start modal detail pedido  -->
                        <v-dialog v-model="dialogDetail" >
                            <v-card color="grayA">
                                <v-card-title>
                                    <span class="text-h5">Detalle de la Orden</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                    <!-- STAR estados de la orden      -->
                                        <v-row>
                                            <v-spacer></v-spacer>
                                        <!-- ABIERTA  -->
                                            <v-col cols="12" sm="2" md="2" align="center">
                                                <v-col cols="12" sm="12" md="12">
                                                    <h4>Abierta</h4>
                                                    <v-spacer></v-spacer>
                                                </v-col>
                                                <template v-if="estado_orden=='Abierta'">
                                                    <div class="circulo">
                                                        <v-img
                                                        max-height="60"
                                                        max-width="60"
                                                        :src="$baseUrl +'abierta.png'"
                                                        >
                                                        </v-img>
                                                    </div>
                                                </template>
                                                <template v-if="estado_orden!='Abierta'">
                                                    <div class="circuloDesactivate">
                                                        <v-img
                                                        max-height="60"
                                                        max-width="60"
                                                        :src="$baseUrl +'abierta.png'"
                                                        >
                                                        </v-img>
                                                    </div>
                                                </template>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-spacer></v-spacer>
                                                    <h4>{{fecha_envio}}</h4>
                                                    <v-spacer></v-spacer>
                                                </v-col>

                                            </v-col>
                                        <!-- RECOGIDA  -->    
                                            <v-col cols="12" sm="2" md="2" align="center">
                                                <v-col cols="12" sm="12" md="12">
                                                    <h4>Recogida</h4>
                                                    <v-spacer></v-spacer>
                                                </v-col>
                                                <template v-if="estado_orden=='Recogida'">
                                                    <div class="circulo">
                                                        <v-img
                                                        max-height="60"
                                                        max-width="60"
                                                        :src="$baseUrl +'recogida.png'"
                                                        ></v-img>
                                                    </div>
                                                </template>
                                                <template v-if="estado_orden!='Recogida'">
                                                <div class="circuloDesactivate">
                                                    <v-img
                                                    max-height="60"
                                                    max-width="60"
                                                    :src="$baseUrl +'recogida.png'"
                                                    ></v-img>
                                                </div>
                                                </template>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-spacer></v-spacer>
                                                    <h4>{{fecha_hora_recogida}}</h4>
                                                    <v-spacer></v-spacer>
                                                </v-col>
                                            </v-col>
                                        <!-- PROCESO   -->
                                            <v-col cols="12" sm="2" md="2" align="center">
                                                <v-col cols="12" sm="12" md="12">
                                                    <h4>En proceso</h4>
                                                    <v-spacer></v-spacer>
                                                </v-col>
                                                <template v-if="estado_orden=='Proceso'">
                                                    <div class="circulo">
                                                        <v-img
                                                        max-height="60"
                                                        max-width="60"
                                                        :src="$baseUrl +'proceso.png'"
                                                        ></v-img>
                                                    </div>
                                                </template>
                                                <template v-if="estado_orden!='Proceso'">
                                                    <div class="circuloDesactivate">
                                                        <v-img
                                                        max-height="60"
                                                        max-width="60"
                                                        :src="$baseUrl +'proceso.png'"
                                                        ></v-img>
                                                    </div>
                                                </template>
                                                <v-col cols="12" sm="12" md="12" align-self="center">
                                                    <v-spacer></v-spacer>
                                                    <h4>{{fecha_hora_proceso}}</h4>
                                                    <v-spacer></v-spacer>
                                                </v-col>
                                            </v-col>
                                        <!-- ENTREGA   -->
                                            <v-col cols="12" sm="2" md="2" align="center">
                                                <v-col cols="12" sm="12" md="12">
                                                    <h4>En entrega</h4>
                                                    <v-spacer></v-spacer>
                                                </v-col>
                                                <template v-if="estado_orden=='En entrega'">
                                                    <div class="circulo">
                                                        <v-img
                                                        max-height="60"
                                                        max-width="60"
                                                        :src="$baseUrl +'entrega.png'"
                                                        ></v-img>
                                                    </div>
                                                </template>
                                                <template v-if="estado_orden!='En entrega'">
                                                <div class="circuloDesactivate">
                                                    <v-img
                                                    max-height="60"
                                                    max-width="60"
                                                    :src="$baseUrl +'entrega.png'"
                                                    ></v-img>
                                                </div>
                                                </template>
                                                <v-col cols="12" sm="12" md="12" >
                                                    <v-spacer></v-spacer>
                                                    <h4>{{fecha_hora_entrega}}</h4>
                                                    <v-spacer></v-spacer>
                                                </v-col>
                                            </v-col>
                                        <!-- FINALIZADA O CANCELADA     -->
                                            <v-col cols="12" sm="2" md="2" align="center">
                                                <v-col cols="12" sm="12" md="12">
                                                    <h4>Cancelada</h4>
                                                    <v-spacer></v-spacer>
                                                </v-col>
                                                <template v-if="estado_orden=='Entregada'">
                                                <div class="circulo">
                                                    <v-img
                                                    max-height="60"
                                                    max-width="60"
                                                    :src="$baseUrl +'entregada.png'"
                                                    ></v-img>
                                                </div>
                                                </template>
                                                <template v-if="estado_orden=='Cancelada'">
                                                <div class="circuloCancel">
                                                    <v-img
                                                    max-height="60"
                                                    max-width="60"
                                                    :src="$baseUrl +'entregada.png'"
                                                    ></v-img>
                                                </div>
                                                </template>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-spacer></v-spacer>
                                                    <h4>{{fecha_hora_entregada}}</h4>
                                                    <v-spacer></v-spacer>
                                                </v-col>
                                            </v-col>
                                            <v-spacer></v-spacer>
                                        </v-row>
                                    <!-- END estados de la orden      --> 
                                        <v-row>
                                            <v-col cols="12" sm="4" md="4">
                                                <v-text-field v-model="numero_orden" label="Numero orden" readonly outlined>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="4" md="4">
                                                <v-text-field v-model="tipo_orden" label="Tipo" readonly outlined>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="4" md="4" >
                                                <v-text-field v-model="ida_y_vuelta" label="Ida y vuelta" readonly outlined>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3" md="3" >
                                                <v-text-field v-model="estado_orden" label="Estado" readonly outlined>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3" md="3" >
                                                <v-text-field v-model="fecha_creacion" label="Fecha Creación" readonly outlined>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3" md="3" >
                                                <v-text-field v-model="fecha_envio" label="Fecha Envio" readonly outlined>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3" md="3" >
                                                <v-text-field v-model="horario_entrega" label="Horario entrega" readonly outlined>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="4" md="4" >
                                                <v-text-field v-model="cliente_orden" label="Cliente" readonly outlined>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="4" md="4">
                                                <v-text-field v-model="domiciliario_orden" label="Domiciliario asignado" readonly outlined>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="4" md="4">
                                                <v-text-field v-model="valor_pedido" label="Valor del pedido" readonly outlined>
                                                </v-text-field>
                                            </v-col>

                                            <!-- start datos origen  -->
                                            <v-col cols="12" sm="12" md="12">
                                                <span class="text-h6">Datos origen</span>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-text-field v-model="origen_nombre" label="Nombre origen" readonly outlined>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-text-field v-model="origen_telefono" label="Teléfono origen" readonly outlined>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="4" md="4">
                                                <v-text-field v-model="origen_municipio" label="Municipio origen" readonly outlined>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="8" md="8">
                                                <v-text-field v-model="origen_direccion" label="Dirección origen" readonly outlined>
                                                </v-text-field>
                                            </v-col>
                                            <!-- end datos origen  -->

                                            <!-- start datos destino  -->
                                            <v-col cols="12" sm="12" md="12">
                                                <span class="text-h6">Datos destino</span>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-text-field v-model="destino_nombre" label="Nombre destino" readonly outlined>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-text-field v-model="destino_telefono" label="Teléfono destino" readonly outlined>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="4" md="4">
                                                <v-text-field v-model="destino_municipio" label="Municipio destino" readonly outlined>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="8" md="8">
                                                <v-text-field v-model="destino_direccion" label="Dirección destino" readonly outlined>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="|12" md="|12">
                                                <v-text-field v-model="id_pago_wompi" label="Referencia pago" readonly outlined>
                                                </v-text-field>
                                            </v-col>
                                            <!-- end datos origen  -->
                                            <!-- start datos destino  -->

                                        </v-row>
                                        <!-- foto evidencia      -->
                                        <v-layout align-center justify-center>
                                            <v-card
                                            elevation="24"
                                            outlined
                                            >
                                            <div class="home">
                                                <img alt="Vue logo" v-bind:src="foto" width="250px">
                                            </div>
                                            <v-card-title>Foto evidencia</v-card-title>
                                            </v-card>
                                        </v-layout> 
                                    </v-container>
                                </v-card-text>
                
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blueA" text @click="close" >
                                        Cerrar
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    <!-- end modal detail pedido  -->

                    <!-- start modal reasignar pedido  -->
                        <v-dialog v-model="dialogReschedule" max-width="500px">
                            <v-card>
                                <v-card-title>
                                    <v-spacer></v-spacer>
                                        Cambiar estado de orden &nbsp;<h4>{{ asigOrden}}</h4>.
                                    <v-spacer></v-spacer>
                                </v-card-title>
                                <v-card-text>
                                    <template>
                                        <v-data-table
                                        :headers="headersEstados"
                                        :items  ="estados"
                                        class   ="elevation-1"
                                        >
                                            <!-- start opciones Editar - activar/desactivar  -->
                                            <template v-slot:item.seleccionar="{ item }" >
                                                <v-btn color="blueA" @click="reschedule(item)" class="mx-4" fab dark small>
                                                    <v-icon dark >check</v-icon>
                                                </v-btn>
                                            </template>
                                            <!-- end opciones Editar - activar/desactivar  -->

                                        </v-data-table>
                                    </template>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blueA" text @click="close">
                                        Cerrar
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    <!-- end modal reasignar pedido  -->

                    <!-- start modal asignar domiciliario  -->
                        <v-dialog v-model="dialogAsigDomi" >
                            <v-card>
                                <v-card-title>
                                    <v-spacer></v-spacer>
                                         <v-col cols="12" sm="8">
                                            Asignar domiciliario a orden &nbsp;<h4>{{ asigOrden}}</h4>.
                                        </v-col>                          
                                        <v-col cols="12" sm="4">
                                            <v-text-field v-model="texto" @keyup.enter="listDomic()" append-icon="search" label="Buscar domiciliario" outlined></v-text-field>
                                        </v-col>
                                    <v-spacer></v-spacer>
                                </v-card-title>
                                <v-card-text>
                                    <template>
                                        <v-data-table
                                        :headers="headersDomiciliarios"
                                        :items  ="domiciliarios"
                                        class   ="elevation-1"
                                        >
                                            <!-- start campo disponible - no disponible  -->
                                            <template v-slot:item.disponible="props">
                                                <td>
                                                    <div v-if="props.item.disponible">
                                                        <v-chip color="green" outlined>
                                                            <span>Disponible</span>
                                                        </v-chip>
                                                    </div>
                                                    <div v-else>
                                                        <v-chip color="red" outlined>
                                                            <span>No disponible</span>
                                                        </v-chip>
                                                    </div>
                                                </td>
                                            </template>
                                            <!-- end campo disponible - no disponible  -->

                                            <!-- start campo activo - no inactivo  -->
                                            <template v-slot:item.estado="props">
                                                <td>
                                                    <div v-if="props.item.estado">
                                                        <span class="green--text">Activo</span>
                                                    </div>
                                                    <div v-else>
                                                        <span class="red--text">Inactivo</span>
                                                    </div>
                                                </td>
                                            </template>
                                            <!-- start campo activo - no inactivo  -->

                                            <!-- start opciones Editar - activar/desactivar  -->
                                            <template v-slot:item.seleccionar="{ item }" >
                                                <v-btn color="blueA" @click="assignDomic(item)" class="mx-4" fab dark small>
                                                    <v-icon dark >check</v-icon>
                                                </v-btn>
                                            </template>
                                            <!-- end opciones Editar - activar/desactivar  -->

                                        </v-data-table>
                                    </template>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blueA" text @click="close">
                                        Cerrar
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    <!-- end modal asignar domiciliario  -->

                    <!-- start modal asignar horario entrega  -->
                        <v-dialog v-model="dialogAsigHorario" width="400">
                            <v-card>
                                <v-card-title>
                                  Asignar horario a orden &nbsp;<h4>{{ asigOrden}}</h4>.
                                </v-card-title>
                                <v-card-text>
                                    <template>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-select v-model="horario_entrega"
                                            :items="horarios" label="Rango horario">
                                            
                                            </v-select>
                                        </v-col>
                                    </template>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blueA" text @click="close">
                                        Cerrar
                                    </v-btn>
                                    <v-btn color="greenA" text @click="saveHorario">
                                        Guardar
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    <!-- end modal asignar horario entrega  -->

                    </v-toolbar>
                </template>
                
            <!-- campo fecha envio  -->
                <template v-slot:item.fecha_envio="{ item }">{{filterDate(item.fecha_envio)}}
                </template>

            <!-- campo opciones  -->
                <template v-slot:item.opciones="{ item }">
                    <template v-if="isAdmin">
                        <v-btn color="pinkA" @click="modalAssignDomic(item)" class="mr-2 mb-1" fab dark small title="Asignar domiciliario">
                            <v-icon dark >moped</v-icon>
                        </v-btn>
                    </template>

                    <v-btn color="blueA" @click="detail(item)" class="mr-2 mb-1" fab dark small title="Descripcion de la orden">
                       <v-icon color="white" >view_list</v-icon>
                    </v-btn>
                    <template v-if="isAdmin">
                        <v-btn color="aquaA" @click="modalAssignHorario(item)" class="mr-2 mb-1" fab dark small title="Asignar horario de entrega">
                            <v-icon dark >alarm</v-icon>
                        </v-btn>
                        <v-btn color="greenA" @click="modalreschedule(item)" class="mr-2 mb-1"fab dark small title="Cambiar estado de la orden">
                            <v-icon dark >message</v-icon>
                        </v-btn>
                    </template>

                </template>

            <!-- boton recargar  -->
                <template v-slot:no-data>
                    <v-btn color="blueA" @click="listCancel()" dark>
                    Recargar listado
                    </v-btn>
                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
</template>


<script>
import axios from 'axios';

export default {
    data(){
        return {
            dialogDetail: false,
            dialogAsigDomi: false,
            dialogReschedule: false,
            dialogAsigHorario : false,
            search: '',
            pedidos: [],
            domiciliarios: [],
            estados: [],
            texto:'',
            horarios:['8:00 am a 12:00m', '12:00m a 5:00pm' ],
            horario_entrega:'',
            headers: [
                { text: 'Numero Orden', value:  'numero_orden',              sortable:true },
                { text: 'Tipo',         value:  'tipo_orden.nombre',         sortable:true },
                { text: 'Estado',       value:  'estado_orden.nombre_estado',sortable:true },
                { text: 'Cliente',      value:  'cliente_orden.nombre',      sortable:true },
                { text: 'Fecha Envío',  value:  'fecha_envio',               sortable:true },
                { text: 'Referencia Pago', value:  'id_pago_wompi',          sortable:true },
                { text: 'Hora entrega', value:  'horario_entrega',           sortable:true },
                { text: 'Domiciliario', value:  'domiciliario_orden.nombre', sortable:true },
                { text: 'Opciones',     value:  'opciones',                  sortable:false},
            ],
            headersEstados: [
                { text: 'Numero de estado',   value:  'num_estado',   sortable:true  },
                { text: 'Nombre',             value:  'nombre_estado',sortable:false },
                { text: 'Seleccionar', value:  'seleccionar', sortable:false  },
            ],
            headersDomiciliarios: [
                { text: 'Nombre',   value:  'nombre',   sortable:true   },
                { text: 'Documento',value:  'documento',sortable:false  },
                { text: 'Placa',    value:  'placa',    sortable:true   },
                { text: 'Telefono', value:  'telefono', sortable:false  },
                { text: 'Correo',   value:  'correo',   sortable:true   },
                { text: 'Estado en App',value: 'disponible',   sortable:false  },
                { text: 'Estado',   value:  'estado',   sortable:false  },
                { text: 'Seleccionar', value:  'seleccionar', sortable:false  },
            ],
            editedIndex:        -1,
            _id:                '',
            numero_orden:       '',
            foto:               '',
            tipo_orden:         '',
            ida_y_vuelta:       '',
            estado_orden:       '',
            fecha_creacion:     '',
            fecha_envio:        '',
            cliente_orden:      '',
            domiciliario_orden: '',
            valor_pedido:       '',
            origen_nombre:      '',
            origen_telefono:    '',
            origen_municipio:   '',
            origen_direccion:   '',
            destino_nombre:     '',
            destino_telefono:   '',
            destino_municipio:  '',
            destino_direccion:  '',
            validMessage:       [],
            adAction:           false,
            asigOrden:          '',
            fecha_hora_recogida:'',
            fecha_hora_proceso: '',
            fecha_hora_entrega: '',
            fecha_hora_entregada:'',
            id_pago_wompi:       '',
            token: this.$store.state.token,
        }
    },

    computed: {
        formTitle() {
        return this.editedIndex === -1 ? 'Crear Orden' : 'Editar Orden'
        },
        isAdmin(){
        return this.$store.state.nombre && this.$store.state.nombre.rol == 'Administrador';
        },
        isRegular(){
        return this.$store.state.nombre && this.$store.state.nombre.rol == 'Usuario Regular';
        },
    },

    watch: {
        dialogDetail (val) {
        val || this.close()
        },
    },

    created() {
        this.listCancel();
        this.listEstados();
    },

    methods: {
        filterDate(date) {
            return date.slice(0, 10);
        },

        listCancel(){
            let me = this;
            let headers = {headers: {token: this.token}};
            axios.get('api/pedido/listcancel', headers)
            .then(function(response){
                me.pedidos    =   response.data.pedidos;
            })
            .catch(function(error){
                console.log(error);
            })
        },

        listDomic(){
            let me = this;
            let headers = {headers: {token: this.token}};
            axios.get('api/domiciliario/list?search='+this.texto, headers)
            .then(function(response){
                me.domiciliarios  =   response.data.domiciliarios;
            })
            .catch(function(error){
                console.log(error);
            })
        },

        listEstados(){
            let me = this;
            let headers = {headers: {token: this.token}};
            axios.get('api/estadoorden/list', headers)
            .then(function(response){
                me.estados  =   response.data.estados;
            })
            .catch(function(error){
                console.log(error);
            })
        },

        clear(){
            this.numero_orden       ='';
            this.tipo_orden         ='';
            this.ida_y_vuelta       ='';
            this.estado_orden       ='';
            this.fecha_creacion     ='';
            this.fecha_envio        ='';
            this.cliente_orden      ='';
            this.domiciliario_orden ='';
            this.valor_pedido       ='';
            this.origen_nombre      ='';
            this.origen_telefono    ='';
            this.origen_municipio   ='';
            this.origen_direccion   ='';
            this.destino_nombre     ='';
            this.destino_telefono   ='';
            this.destino_municipio  ='';
            this.destino_direccion  ='';
            this.validMessage       =[];
            this.editedIndex        =-1;
            this.asigOrden          ='';
        },

        detail(item) {
            let fCreacion = this.filterDate(item.fecha_creacion);
            let fEnvio = this.filterDate(item.fecha_envio);
            this.numero_orden           = item.numero_orden;
            this.tipo_orden             = item.tipo_orden.nombre; 
            this.ida_y_vuelta           = item.ida_y_vuelta; 
            this.estado_orden           = item.estado_orden.nombre_estado; 
            this.fecha_creacion         = fCreacion; 
            this.fecha_envio            = fEnvio; 
            this.cliente_orden          = item.cliente_orden.nombre; 
            this.valor_pedido           = item.valor_pedido.$numberDecimal; 
            this.domiciliario_orden     = item.domiciliario_orden.nombre; 
            this.origen_nombre          = item.origen_nombre;
            this.origen_telefono        = item.origen_telefono;
            this.origen_municipio       = item.origen_municipio;
            this.origen_direccion       = item.origen_direccion;
            this.destino_nombre         = item.destino_nombre;
            this.destino_telefono       = item.destino_telefono;
            this.destino_municipio      = item.destino_municipio;
            this.destino_direccion      = item.destino_direccion;
            this.dialogDetail           = true;
            this.fecha_hora_recogida    = item.fecha_hora_recogida;
            this.fecha_hora_proceso     = item.fecha_hora_proceso;
            this.fecha_hora_entrega     = item.fecha_hora_entrega;
            this.fecha_hora_entregada   = item.fecha_hora_entregada;
            this.foto                   = this.$baseUrl+item.foto;
            this.id_pago_wompi          = item.id_pago_wompi;
            this.horario_entrega        = item.horario_entrega;
        },

        modalreschedule(item) {
            this.asigOrden          = item.numero_orden;
            this._id                = item._id;
            this.dialogReschedule   = true;
        },
        
        // reprogramar orden
        reschedule(item) {
            let me = this;
             let headers = {headers: {token: this.token}};
            // reprogramar pedido
            axios.put('api/pedido/updateadmin',{
                '_id'               :this._id,
                'estado_orden'      :item._id,
                }, headers)
                .then(function (response) {
                    me.clear();
                    me.close();
                    me.listCancel()
                })
                .catch(function (error) {
                    console.log(error);
                })
        },

        modalAssignDomic(item) {
            this.asigOrden      = item.numero_orden;
            this._id            = item._id;
            this.dialogAsigDomi = true;
        },
        
        // update asignar domiciliario
        assignDomic(item){
            let me = this;
            let nOrden = this.asigOrden;
            let headers = {headers: {token: this.token}};
            axios.put('api/pedido/updateadmin',{
            '_id'               :this._id,
            'domiciliario_orden':item._id,
            }, headers)
            .then(function (response) {
                axios.post('api/domiciliario/notificationneworder',{
                'numero_orden':nOrden,
                'fire_token':item.token_firebase,
                })
                .then(function (response) {

                })
                .catch(function (error) {
                    console.log(error);
                })

                me.clear();
                me.close();
                me.listCancel();
            })
            .catch(function (error) {
                console.log(error);
            })
        },

        // modal asignar horario entrega
        modalAssignHorario(item) {
            this.asigOrden      = item.numero_orden;
            this._id            = item._id;
            this.dialogAsigHorario = true;
        },

        saveHorario() {
            let me = this;
            let headers = {headers: {token: this.token}};
            axios.put('api/pedido/updateadmin',{
            '_id'            :this._id,
            'horario_entrega':this.horario_entrega,
            }, headers)
            .then(function (response) { 
                me.clear();
                me.close();
                me.listCancel();
            })
            .catch(function (error) {
                console.log(error);
            })
            console.log();
        },
        close() {
            this.dialogDetail     = false;
            this.dialogAsigDomi   = false;
            this.dialogReschedule = false;
            this.dialogAsigHorario = false;
            this.clear();
        },
    },
}
</script>
<style scoped>
.circulo {
    width: 100px;
    height: 100px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-color: #5AB031;
    border-width: 5px;
    border-style: solid;
    border-color: #E0E5EC;
    box-shadow: 5px 5px 8px 2px gray, -5px -5px 8px 5px white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.circuloDesactivate {
    width: 100px;
    height: 100px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-color: #2E67B0;
    border-width: 5px;
    border-style: solid;
    border-color: #E0E5EC;
    box-shadow: 5px 5px 8px 2px gray, -5px -5px 8px 5px white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.circuloCancel {
    width: 100px;
    height: 100px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-color: #f85c02;
    border-width: 5px;
    border-style: solid;
    border-color: #E0E5EC;
    box-shadow: 5px 5px 8px 2px gray, -5px -5px 8px 5px white;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>