import Vue from 'vue'
import Vuex from 'vuex'
import decode from 'jwt-decode'
import router  from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token   :null,
    nombre :null
  },
  mutations: {
    setToken(state, token){
      state.token = token;
    },
    setNombre(state, nombre){
      state.nombre = nombre;
    }
  },
  actions: {
    saveToken({commit}, token){
      commit("setToken", token);
      commit("setNombre", decode(token));
      localStorage.setItem("token", token);
    },
    autoLogin({commit}){
      let token = localStorage.getItem("token");
      if (token) {
        commit("setToken", token);
        commit("setNombre", decode(token));
      }
      router.push({name:'home'}).catch(() => {});;
    },
    exit({commit}){
      commit("setToken", null);
      commit("setNombre", null);
      localStorage.removeItem("token");
      router.push({name: 'login'}).catch(() => {});;
    }
  },
})
