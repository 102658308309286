<template>
    <v-layout align-start>
        <v-flex>
            <!-- start tabla domicilliarios  -->
            <v-data-table
            :headers="headers"
            :items  ="domiciliarios"
            :search = "search"
            class   ="elevation-1"
            >
                <template v-slot:top>
                    <v-toolbar flat >
                        <v-toolbar-title>Domiciliarios</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>

                        <v-spacer></v-spacer>

                    <!-- start barra de busqueda  -->
                        <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Búsqueda" single-line hide-details>
                        </v-text-field>
                    <!-- end barra de busqueda  -->

                        <v-spacer></v-spacer>

                    <!-- start modal NUEVO o ACTUALIZAR domiciliario  -->
                        <template v-if="isAdmin">
                            <v-dialog v-model="dialog" max-width="500px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="greenA" dark class="mb-2" v-bind="attrs" v-on="on" raised rounded>
                                        Nuevo
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        <span class="text-h5">{{ formTitle }}</span>
                                        <v-spacer></v-spacer>
                                        <v-btn icon @click="dialog = false">
                                            <v-icon>close</v-icon>
                                        </v-btn>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-text-field v-model="nombre" label="Nombre">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="4">
                                                    <v-text-field v-model="documento" label="Documento">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="4">
                                                    <v-text-field v-model="placa" label="Placa">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="4" >
                                                    <v-text-field v-model="telefono" label="Telefono">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12" >
                                                    <v-text-field v-model="correo" label="Correo">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12" v-show="valid">
                                                    <div class="red--text" v-for="v in validMessage" :key="v" v-text="v">
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                    
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <template v-if="formTitle=='Editar domiciliario'">
                                        <v-btn color="red" text @click="modalDeleteDomicilairio()" >
                                            Eliminar Domiciliario
                                        </v-btn>
                                        </template>
                                        <v-btn color="blueA" text @click="close" >
                                            Cancelar
                                        </v-btn>
                                        <v-btn color="greenA" text @click="save" >
                                            Guardar
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </template>
                    <!-- end modal NUEVO o ACTUALIZAR domiciliario  -->

                    <!-- start modal Activar/desactivar domiciliario  -->
                        <v-dialog v-model="dialogActDeact" max-width="500px">
                            <v-card>
                                <v-card-title >
                                    <template v-if="adAction==false">
                                    <v-spacer></v-spacer>
                                    ¿Estás seguro de Activar a {{adNombre}}?
                                    <v-spacer></v-spacer>
                                    </template>
                                    <template v-if="adAction==true">
                                    <v-spacer></v-spacer>
                                    ¿Estás seguro de Desactivar a {{adNombre}}?
                                    <v-spacer></v-spacer>
                                    </template>
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blueA" text @click="closeDelete">
                                        Cancelar
                                    </v-btn>
                                    
                                    <v-btn color="greenA" text @click="actionActDeact(adAction)">
                                        Aceptar
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    <!-- end modal Activar/desactivar domiciliario  -->
                    
                    <!-- start modal Eliminar domiciliario  -->
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title >
                                    <v-spacer></v-spacer>
                                    ¿Estás seguro de Eliminar a {{nombre}}?
                                    <v-spacer></v-spacer>
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blueA" text @click="close">
                                        Cancelar
                                    </v-btn>
                                    
                                    <v-btn color="greenA" text @click="deleteDomicilairio()">
                                        Aceptar
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    <!-- end modal Eliminar domiciliario  -->

                    <!-- start modal UBICACION domiciliario  -->
                        <v-dialog v-model="dialogUbication" max-width="100%" style="height: 90vh;">
                                <v-lazy class="mapa">
                                    <l-map
                                    :zoom="zoom"
                                    :center="center"
                                    ref="myMap"
                                    >
                                        <l-tile-layer
                                            :url="url"
                                            :attribution="attribution"
                                        ></l-tile-layer>
                                        <template v-for="(punto, index) in items(puntos)">
                                            <l-layer-group >
                                                <l-marker :lat-lng="punto.position" ref="marcadores">
                                                    <l-tooltip :options="{ permanent: true}">
                                                        {{index+1}}
                                                    </l-tooltip>
                                                </l-marker>
                                            </l-layer-group>
                                        </template>
                                        <l-control position="bottomright" >
                                        <v-btn color="blueA" dark class="mb-2" @click="close" raised rounded>
                                            Cerrar
                                        </v-btn>
                                        </l-control position="topright" >
                                        <l-control>
                                            <v-btn color="blueA" dark class="mb-2" @click="centrar" raised rounded>
                                                <v-icon color="white" >my_location</v-icon>
                                            </v-btn>
                                        </l-control>
                                    </l-map>
                                </v-lazy>

                        </v-dialog>
                    <!-- end modal UBICACION domiciliario  -->

                    </v-toolbar>
                </template>
                
                <!-- start campo disponible - no disponible  -->
                <template v-slot:item.disponible="props">
                    <td>
                        <div v-if="props.item.disponible">
                            <v-chip color="greenA" outlined>
                                <span>Disponible</span>
                            </v-chip>
                        </div>
                        <div v-else>
                            <v-chip color="pinkA" outlined>
                                <span>No disponible</span>
                            </v-chip>
                        </div>
                    </td>
                </template>
                <!-- end campo disponible - no disponible  -->

                <!-- start campo activo - no inactivo  -->
                <template v-slot:item.estado="props">
                    <td>
                        <div v-if="props.item.estado">
                            <span class="greenA--text">Activo</span>
                        </div>
                        <div v-else>
                            <span class="pinkA--text">Inactivo</span>
                        </div>
                    </td>
                </template>
                <!-- end campo activo - no inactivo  -->

                <!-- start opciones Editar - activar/desactivar  -->
                <template v-slot:item.opciones="{ item }">
                    <template v-if="isAdmin">
                        <v-btn color="blueA" @click="editItem(item)" class="mr-2 mt-1" fab dark small title="Editar domiciliario">
                        <v-icon color="white" >edit</v-icon>
                        </v-btn>
                    </template>
                    <template v-if="isAdmin">
                    <template v-if="item.estado">
                        <v-btn color="pinkA" @click="modalActivateDeactivate(false,item)" class="mr-2 mt-1 mb-1" fab dark small title="Desactivar domicilairio">
                        <v-icon color="white" >block</v-icon>
                        </v-btn>
                    </template>
                    <template v-else>
                        <v-btn color="greenA" @click="modalActivateDeactivate(true,item)" class="mr-2 mt-1 mb-1" fab dark small title="Activar cliente">
                        <v-icon color="white" >check</v-icon>
                        </v-btn>
                    </template>
                    </template>
                    <v-btn color="aquaA" @click="openDialogUbication(item)" class="mr-2 mt-1 mb-1" fab dark small title="Ver ubicación del domiciliario">
                       <v-icon color="white" >place</v-icon>
                    </v-btn>
                </template>
                <!-- end opciones Editar - activar/desactivar  -->

                <!-- start boton recargar lista -->
                <template v-slot:no-data>
                    <v-btn color="blueA" @click="list()" raised rounded>
                    Recargar listado
                    </v-btn>
                </template>
                <!-- end boton recargar lista -->

            </v-data-table>
            <!-- end tabla domicilliarios  -->
        </v-flex>
    </v-layout>
</template>


<script>
import Vue from 'vue';
import axios from 'axios';
import VueAlertify from 'vue-alertify';
Vue.use(VueAlertify);
import { Icon } from "leaflet";
import { LMap, LTileLayer, LControl, LMarker, LPopup, LTooltip,LLayerGroup } from "vue2-leaflet";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
export default {
    
    components: {
    LMap,
    LTileLayer,
    LControl,
    LMarker,
    LPopup, 
    LTooltip,
    LLayerGroup
    },
    // data de la tabla 
    data(){
        return {
            mapaD:null,
            ubicaciones:'',
            puntos:[],
            empresa:'',
            domiciliarios: [],
            zoom: 13,
            center: '',
            centrado:'',
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            showParagraph: false,
            mapOptions: {
                zoomSnap: 0.5
            },
            showMap: true,
            dialog: false,
            dialogUbication:false,
            dialogActDeact:false,
            dialogDelete:false,
            search: '',
            headers: [
                { text: 'Nombre',   value:  'nombre',   sortable:true   },
                { text: 'Documento',value:  'documento',sortable:false  },
                { text: 'Placa',    value:  'placa',    sortable:true   },
                { text: 'Telefono', value:  'telefono', sortable:false  },
                { text: 'Correo',   value:  'correo',   sortable:true   },
                { text: 'Estado en App',value: 'disponible',   sortable:false  },
                { text: 'Estado',   value:  'estado',   sortable:false  },
                { text: 'Opciones', value:  'opciones', sortable:false  },
            ],
            editedIndex: -1,
            _id:'',
            nombre:'',
            documento:'',
            placa:'',
            telefono:'',
            correo:'',
            valid:0,
            validMessage:[],
            adAction:false,
            adNombre:'',
            adId:'',
            token: this.$store.state.token,            
        }
    },

    // titulo del modal 
    computed: {
        formTitle () {
        return this.editedIndex === -1 ? 'Crear domiciliario' : 'Editar domiciliario'
        },
        isAdmin(){
        return this.$store.state.nombre && this.$store.state.nombre.rol == 'Administrador';
        },
        isRegular(){
        return this.$store.state.nombre && this.$store.state.nombre.rol == 'Usuario Regular';
        },
    },

    watch: {
        dialog (val) {
        val || this.close()
        },
        dialogUbication (val) {
        val || this.close()
        },
        dialogActDeact (val) {
        val || this.closeDelete()
        },
        dialogDelete (val) {
        val || this.closeDelete()
        },
    },

    created () {
        this.list();
        this.center = [6.2729514105738025, -75.59880953439992];
    },

    methods: {

        success(mensaje) {
        this.$alertify.success(mensaje);
        },

        alert(mensaje) {    
            this.$alertify.warning(mensaje);      
        }, 

        centrar(){
            this.center = this.centrado;
        },
                 
        items(p) {
        return p.map((p) => {
            return p;
        })
        },

        validate(){
            this.valid =         0;
            this.validMessage = [];
            if ( this.nombre.length<3 || this.nombre.length>50 ){
                this.validMessage.push('Por favor, el nombre ');
            }
            if ( this.documento.length<5 || this.documento.length>50 ){
                this.validMessage.push('Por favor, revisa el documento.');
            }
            if ( this.placa.length<5 || this.placa.length>10 ){
                this.validMessage.push('Por favor, el numero de la placa.');
            }            
            if ( this.telefono.length<10 || this.telefono.length>10 ){
                this.validMessage.push('Por favor, revisa el telefono.');
            }
            if ( this.correo.length<3 || this.correo.length>50 ){
                this.validMessage.push('El correo no puede estar vacio.');
            }
            if (!this.validEmail(this.correo)){
                this.validMessage.push('Por favor, revisa el correo.');
            }
            if ( this.validMessage.length){
                this.valid = 1;
            }
            return this.valid;                           

        },

        async list(){
            let me = this;
            let headers = {headers: {token: this.token}};
            await  axios.get('api/domiciliario/list', headers)
                .then(function(response){
                    me.domiciliarios  =   response.data.domiciliarios;
                })
                .catch(function(error){
                    console.log(error);
                })
        },

        async listubicaciones(item){
            let me = this;
            let headers = {headers: {token: this.token}};
            await axios.get('api/domiciliario/listubicaciones?_id='+item._id, headers)
            .then(function(response){
                me.ubicaciones = response.data.ubicaciones;
                me.puntos = JSON.parse(me.ubicaciones);
                me.centrado = me.puntos[0].position;
            })
            .catch(function(error){
                me.centrado = [6.2496924,-75.5685486];
            })
        },

        async listempresa(){
            let headers = {headers: {token: this.token}};
            let me = this;
            await axios.get('api/empresa/listforadmin', headers)
            .then(function(response){
                me.empresa = response.data.empresa[0].nombre;
            })
            .catch(function(error){
                me.alert('No se pudo actualizar su domicilairio, verifique que el correo ya esta en uso');
            })
        },

        clear(){
            this._id          ='';
            this.nombre       ='';
            this.documento    ='';
            this.placa        ='';
            this.telefono     ='';
            this.correo       ='';
            this.valid        =0;
            this.validMessage =[];
            this.editedIndex  =-1;
            this.adNombre     ='';
            this.adId         ='';
        },

        async save () {
            await this.listempresa();
            let me = this;
            if( this.validate()){
                return;
            }

            if (this.editedIndex > -1) {
                let headers = {headers: {token: this.token}};
                // Editar domiciliario
                axios.put('api/domiciliario/update',{
                    '_id'       :this._id,
                    'nombre'    :this.nombre,
                    'documento' :this.documento,
                    'placa'     :this.placa,
                    'telefono'  :this.telefono,
                    'correo'    :this.correo,
                    }, headers)
                    .then(function (response) {
                        me.clear();
                        me.close();
                        me.list();
                        me.success('se editó el domiciliario '+response.data.domiciliario['nombre']);
                    })
                    .catch(function (error) {
                        me.clear();
                        me.close();
                        me.list();
                        me.alert('No se pudo actualizar su domicilairio, verifique que el correo ya esta en uso');
                    })
            } else {
                let headers = {headers: {token: this.token}};
                // Guardar domiciliario
                axios.post('api/domiciliario/register',{
                    'nombre'    :this.nombre,
                    'empresa'   :this.empresa,
                    'documento' :this.documento,
                    'placa'     :this.placa,
                    'telefono'  :this.telefono,
                    'correo'    :this.correo,
                    'contrasena':this.documento
                    }, headers)
                    .then(function (response) {
                        me.clear();
                        me.close();
                        me.list();
                        me.success('Se creó el domiciliario');
                    })
                    .catch(function (error) {
                        me.clear();
                        me.close();
                        me.list();
                        me.alert('No se pudo registrar su domicilairio, verifique que no exista en la base de datos o que el correo ya esta en uso');
                    })
            }
        
        },

        editItem (item) {
            this._id        = item._id;
            this.nombre     = item.nombre;     
            this.documento  = item.documento;
            this.placa      = item.placa;
            this.telefono   = item.telefono;
            this.correo     = item.correo;
            this.dialog     = true;
            this.editedIndex= 1;
        },

        modalActivateDeactivate (action, item) {
            this.dialogActDeact = true;
            this.adNombre       = item.nombre;
            this.adId           = item._id;
            if (action==true){
                this.adAction = false;
            }else if (action==false){
                this.adAction = true;
            }else{
                this.dialogActDeact = false;
            }
        },

        actionActDeact (actDeact) {
            let me = this;
            if (actDeact){
                let headers = {headers: {token: this.token}};
                // Desactivar domiciliario
                axios.put('api/domiciliario/deactivate?_id='+this.adId, null, headers)
                    .then(function (response) {
                        axios.put('api/domiciliario/notavailable?_id='+response.data._id);
                        me.clear();
                        me.close();
                        me.list();
                        me.success('se desactivo el domicilairio '+response.data.nombre);
                    })
                    .catch(function (error) {
                        me.clear();
                        me.close();
                        me.list();
                        me.alert('No se pudo desactivar el domiciliario, por favor intente mas tarde');
                    })
            }else{
                let headers = {headers: {token: this.token}};
                // Activar domiciliario
                axios.put('api/domiciliario/activate?_id='+this.adId, null, headers)
                    .then(function (response) {
                        me.clear();
                        me.close();
                        me.list();
                        me.success('se activo el domiciliario '+response.data.nombre);
                    })
                    .catch(function (error) {
                        me.clear();
                        me.close();
                        me.list();
                        me.alert('No se pudo activar el domiciliario, por favor intente mas tarde');
                    })                
            }
        },

        modalDeleteDomicilairio () {
            this.dialogDelete = true;
        },

        deleteDomicilairio() {
            let headers = {headers: {token: this.token}};
            let me = this;
            // Eliminar domiciliario
            axios.put('api/domiciliario/delete?_id='+me._id, null, headers )
                .then(function (response) {
                    me.clear();
                    me.close();
                    me.list();
                    me.success('se eliminó el domiciliario '+response.data.nombre);
                })
                .catch(function (error) {
                        me.clear();
                        me.close();
                        me.list();
                        me.alert('No se pudo eliminar el domiciliario, por favor intente mas tarde');
                })
        },
        
        async openDialogUbication(item) {
            await this.listubicaciones(item);
            this.dialogUbication = true;
        },

        close () {
            this.clear();
            this.puntos=[],
            this.dialog = false;
            this.dialogActDeact = false;
            this.dialogUbication = false;
            this.dialogDelete = false;
            this.editedIndex = -1
        },

        closeDelete () {
        this.dialogActDeact = false
        this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
        })
        },

        validEmail (correo) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(correo);
        }       

    },
}
</script>

<style scoped>
.mapa {
    width: 100%;
    height: 70vh;
 
}
</style>