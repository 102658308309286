<template>
  <v-app id="app">

    <!-- menu  -->
    <v-navigation-drawer 
    v-model="drawer" 
    :clipped="$vuetify.breakpoint.mgAndUp" 
    app
    v-if="logueado"
    >
        <v-list dense>
            <template v-if="isAdmin || isRegular">
            <v-list-item :to="{name: 'home'}" color="blueA">
                <v-list-item-action>
                  <v-icon>home</v-icon>
                </v-list-item-action>
                <v-list-item-title>Inicio</v-list-item-title>
            </v-list-item>
            </template>
            
            <!-- opciones empresa  -->
            <template v-if="isAdmin">
                <v-list-item :to="{name: 'empresa'}" color="blueA">
                  <v-list-item-action>
                    <v-icon>store</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Información Empresa</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
            </template>

            <!-- opciones tarifas  -->
            <template v-if="isAdmin">
                <v-list-item :to="{name: 'tarifa'}" color="blueA">
                  <v-list-item-action>
                    <v-icon>money</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Tarifas</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
            </template>

            <!-- opciones servicios  -->
            <template v-if="isAdmin">
                <v-list-item :to="{name: 'servicio'}" color="blueA">
                  <v-list-item-action>
                    <v-icon>archive</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Servicios</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
            </template>

            <!-- opciones usuarios  -->
            <template v-if="isAdmin">
                <v-list-item :to="{name: 'usuario'}" color="blueA">
                  <v-list-item-action>
                    <v-icon>person</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Usuarios</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
            </template>

            <!-- opciones domiciliarios  -->
            <template v-if="isAdmin || isRegular">
                <v-list-item :to="{name: 'domiciliario'}" color="blueA">
                  <v-list-item-action>
                    <v-icon>moped</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Gestión domiciliarios</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
            </template>

            <!-- opciones Clientes  -->
            <template v-if="isAdmin || isRegular">
                <v-list-item :to="{name: 'cliente'}" color="blueA">
                  <v-list-item-action>
                    <v-icon>people</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Gestión clientes</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
            </template>

            <!-- opciones Envios  -->
            <template v-if="isAdmin || isRegular">
              <v-list-group color="blueA">
                <v-list-item slot="activator">
                  <v-list-item-content>
                    <v-list-item-title>Envios y diligencias</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <!-- Asignacón y ordenes en proceso  -->
                <v-list-item :to="{name: 'pedido'}">
                  <v-list-item-action>
                    <v-icon>redo</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Asignación y <br> ordenes en proceso</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                 
                 <!-- Reasignar ordenes -->
                <v-list-item :to="{name: 'pedidocancelado'}">
                  <v-list-item-action>
                    <v-icon>undo</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Reasignar ordenes <br> canceladas </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                 <!-- historico ordenes finalizadas-->
                <v-list-item :to="{name: 'pedidofinalizado'}">
                  <v-list-item-action>
                    <v-icon>chrome_reader_mode</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Historico ordenes <br> finalizadas </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </template>

        </v-list>
    </v-navigation-drawer>

    <!-- appbar  -->
    <v-app-bar :clipped="$vuetify.breakpoint.lgAndUp" color="blueA" dark app >
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title style="width: 300px" class="ml-0 pl-3">
        <span  class="hidden-sm-and-down">{{this.nombre}}</span>
      </v-toolbar-title>
         <v-spacer></v-spacer>   
          <v-btn @click="modalExit" v-if="logueado" icon>
            <v-icon>logout</v-icon>
          </v-btn>      
    </v-app-bar>
    <!-- start modal salir -->
    <v-dialog v-model="dialogExit" max-width="500px">
        <v-card>
            <v-card-title >
                <v-spacer></v-spacer>
                ¿Estás seguro de que deseas salir?
                <v-spacer></v-spacer>
            </v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blueA" text @click="close">
                    Cancelar
                </v-btn>
                
                <v-btn color="greenA" text @click="exit">
                    Aceptar
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- end modal salir -->
    <!-- main  -->
    <v-main >
      <v-container fluid fill-height>
        <v-slide-y-transition mode="out-in">
            <router-view>

            </router-view>
        </v-slide-y-transition>
      </v-container>
    </v-main>

    <!-- footer  -->
    <v-footer height="auto">
        <v-layout justify-center>
          <v-flex text-xs-center>
            <v-card flat tile color="blueA" class="white--text">
              <v-card-text class="white--text pt-0">
                IdeasApp &copy; 2022
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  watch: {
    dialogExit (val) {
    val || this.close()
    },
  },
  
  data () {
    return {
      nombre: '',
      drawer: true,
      dialogExit:false,
    }
  },

  computed:{
    logueado(){
      this.nombre = (this.$store.state.nombre==null)?'Acelerar':'Usuario: '+this.$store.state.nombre.nombre;
      return this.$store.state.nombre;
    },
    isAdmin(){
      return this.$store.state.nombre && this.$store.state.nombre.rol == 'Administrador';
    },
    isRegular(){
      return this.$store.state.nombre && this.$store.state.nombre.rol == 'Usuario Regular';
    },
  },
  
  created(){
    this.$store.dispatch("autoLogin");
  },

  methods:{
    modalExit () {
      this.dialogExit = true;
    },

    close () {
    this.dialogExit = false;
    },

    exit(){
      this.$store.dispatch("exit");
      this.dialogExit = false;
    }
  }
 
};
</script>
<style scoped>
.v-application {
  background-color: #E0E5EC;
}
</style>
