<template>
    <v-layout align-start>
        <v-flex>
            <v-data-table
            :headers="headers"
            :items  ="empresa"
            :search = "search"
            class   ="elevation-1"
            >
                <template v-slot:top>
                    <v-toolbar flat >
                        <v-toolbar-title>Información Empresa</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>

                    <!-- modal NUEVO o ACTUALIZAR cliente  -->
                        <v-dialog v-model="dialog" max-width="500px">
                            <template v-slot:activator="{ on, attrs }">
                            </template>
                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                    <v-spacer></v-spacer>
                                    <v-btn icon @click="dialog = false">
                                        <v-icon>close</v-icon>
                                    </v-btn>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-text-field v-model="nombre" label="Nombre" readonly>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6" >
                                                <v-text-field v-model="telefono" label="Teléfono">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" >
                                                <v-text-field v-model="correo" label="Correo">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" v-show="valid">
                                                <div class="red--text"
                                                    v-for="v in validMessage" :key="v" v-text="v">
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blueA" text @click="close" >
                                        Cancelar
                                    </v-btn>
                                    <v-btn color="greenA" text @click="save" >
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                    </v-toolbar>
                </template>

                <template v-slot:item.opciones="{ item }">
                    <v-btn color="blueA" @click="editItem(item)" class="mr-2 mt-1" fab dark small title="Editar datos empresa">
                       <v-icon color="white" >edit</v-icon>
                    </v-btn>

                </template>

                <template v-slot:no-data>
                    <v-btn color="blueA" @click="listEmpresa()">
                    Recargar listado
                    </v-btn>
                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
</template>


<script>
import axios from 'axios';

export default {
    data(){
        return {
            dialog: false,
            search: '',
            empresa: [],
            headers: [
                { text: 'Nombre',   value:  'nombre',   sortable:true   },
                { text: 'Teléfono', value:  'telefono', sortable:false  },
                { text: 'Correo',   value:  'correo',   sortable:true   },
                { text: 'Opciones', value:  'opciones', sortable:false  },
            ],
            editedIndex: -1,
            _id:'',
            nombre:'',
            telefono:'',
            correo:'',
            valid:0,
            validMessage:[],
            dialogActDeact:false,
            adAction:false,
            token: this.$store.state.token,
        }
    },

    computed: {
        formTitle () {
        return this.editedIndex === -1 ? 'Crear Empresa' : 'Editar Empresa'
        },
    },

    watch: {
        dialog (val) {
        val || this.close()
        },
        dialogActDeact (val) {
        val || this.closeDelete()
        },
    },

    created () {
        this.listEmpresa()
    },

    methods: {
        validate(){
            this.valid =         0;
            this.validMessage = [];
            if ( this.nombre.length<3 || this.nombre.length>50 ){
                this.validMessage.push('Por favor, el nombre ');
            }          
            if ( this.telefono.length<10 || this.telefono.length>10 ){
                this.validMessage.push('Por favor, revisa el teléfono.');
            }
            if ( this.correo.length<3 || this.correo.length>50 ){
                this.validMessage.push('El correo no puede estar vacío.');
            }
            if (!this.validEmail(this.correo)){
                this.validMessage.push('Por favor, revisa el correo.');
            }
            if ( this.validMessage.length){
                this.valid = 1;
            }
            return this.valid;                           

        },

        listEmpresa(){
            let me = this;
            let headers = {headers: {token: this.token}};
            axios.get('api/empresa/listforadmin',headers)
            .then(function(response){
                me.empresa    =   response.data.empresa;
            })
            .catch(function(error){
                console.log(error);
            })
        },

        clear(){
            this._id          ='';
            this.nombre       ='';
            this.telefono     ='';
            this.correo       ='';
            this.valid        =0;
            this.validMessage =[];
            this.editedIndex  =-1;
        },

        save () {
            let me = this;
            if( this.validate()){
                return;
            }

            if (this.editedIndex > -1) {
                 let me = this;
                let headers = {headers: {token: this.token}};
                // Editar empresa
                axios.put('api/empresa/update',{
                    '_id'       :this._id,
                    'nombre'    :this.nombre,
                    'telefono'  :this.telefono,
                    'correo'    :this.correo,
                    }, headers)
                    .then(function (response) {
                        me.clear();
                        me.close();
                        me.listEmpresa();
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            } else {

                // Guardar empresa
                axios.post('api/empresa/register',{
                    // TODO: enviar nombre de empresa logueada 
                    'empresa'   :'Empresa1',
                    'nombre'    :this.nombre,
                    'telefono'  :this.telefono,
                    'correo'    :this.correo,
                    }, headers)
                    .then(function (response) {
                        me.clear();
                        me.close();
                        me.listEmpresa();
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            }
        
        },

        editItem (item) {
            this._id        = item._id;
            this.nombre     = item.nombre;
            this.telefono   = item.telefono;
            this.correo     = item.correo;
            this.dialog     = true;
            this.editedIndex= 1;
        },

        close () {
            this.dialog = false;
            this.dialogActDeact = false;
        },

        closeDelete () {
        this.dialogActDeact = false
        this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
        })
        },

        validEmail (correo) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(correo);
        }       

    },
}
</script>