<template>
    <v-layout align-center justify-center>
        <v-flex xs12 sm8 md6 lg5 xl4>
            <v-card>
                <v-toolbar dark color="blueA">
                    <v-toolbar-title>
                        Acceso a Sistema
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-text-field v-model="correo" color="accent" label="Correo electrónico" required>
                    </v-text-field>

                    <v-text-field v-model="contrasena" type="password" color="accent" label="Password" required>
                    </v-text-field>

                    <v-flex class="red--text" v-if=errorM>
                        {{errorM}}
                    </v-flex>
                </v-card-text>
                <v-card-actions class="px-3 pb-3">
                    <v-btn color="greenA" @click="login" raised rounded dark>Ingresar</v-btn>
                </v-card-actions>
            </v-card>
        </v-flex>
    </v-layout>
</template>
<script>
import axios from 'axios';

export default ({
    data () {
        return{
            correo:'',
            contrasena:'',
            errorM: null
        }
    },
    methods: {
        login(){
            axios.post('api/admin/login', {correo: this.correo, contrasena: this.contrasena})
            .then(response=>{
                return response.data;
            })
            .then(data =>{
                this.$store.dispatch("saveToken", data.tokenReturn);
                this.$router.push({name: 'home'});
            })
            .catch(error=>{
                this.errorM= null;
                if (error.response.status = 404) {
                    this.errorM = error.response.data.message;
                }
            });
        }
    }

})
</script>