<template>
    <v-layout align-start>
        <v-flex>
            <v-data-table
            :headers="headers"
            :items  ="usuarios"
            :search = "search"
            class   ="elevation-1"
            >
                <template v-slot:top>
                    <v-toolbar flat >
                        <v-toolbar-title>Usuarios</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                    <!-- barra de busqueda    -->
                        <v-text-field class="text-xs-center" v-model="search" append-icon="search" label="Búsqueda" single-line hide-details>
                        </v-text-field>

                        <v-spacer></v-spacer>

                    <!-- modal NUEVO o ACTUALIZAR cliente  -->
                        <v-dialog v-model="dialog" max-width="500px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="greenA" dark class="mb-2" v-bind="attrs" v-on="on" raised rounded>
                                    Nuevo
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                    <v-spacer></v-spacer>
                                    <v-btn icon @click="dialog = false">
                                        <v-icon>close</v-icon>
                                    </v-btn>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" sm="12" md="12">
                                                <v-text-field v-model="nombre" label="Nombre">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-text-field v-model="documento" label="Documento">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6" >
                                                <v-text-field v-model="telefono" label="Teléfono">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" >
                                                <v-text-field v-model="correo" label="Correo">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12">
                                                <v-select v-model="rol"
                                                :items="tipos" label="Rol">
                                                
                                                </v-select>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" v-show="valid">
                                                <div class="red--text"
                                                    v-for="v in validMessage" :key="v" v-text="v">
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blueA" text @click="close" >
                                        Cancelar
                                    </v-btn>
                                    <v-btn color="greenA" text @click="save" >
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                        <!-- modal Activar/desactivar cliente  -->
                        <v-dialog v-model="dialogActDeact" max-width="500px">
                            <v-card>
                                <v-card-title >
                                    <template v-if="adAction==false">
                                    <v-spacer></v-spacer>
                                    ¿Estás seguro de Activar a {{adNombre}}?
                                    <v-spacer></v-spacer>
                                    </template>
                                    <template v-if="adAction==true">
                                    <v-spacer></v-spacer>
                                    ¿Estás seguro de Desactivar a {{adNombre}}?
                                    <v-spacer></v-spacer>
                                    </template>
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blueA" text @click="closeDelete">
                                        Cancelar
                                    </v-btn>
                                    
                                    <v-btn color="greenA" text @click="ActionActDeact(adAction)">
                                        Aceptar
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                    </v-toolbar>
                </template>

                <template v-slot:item.estado="props">
                    <td>
                        <div v-if="props.item.">
                            <span class="green--text">Activo</span>
                        </div>
                        <div v-else>
                            <span class="red--text">Inactivo</span>
                        </div>
                    </td>
                </template> 

                <template v-slot:item.estado="props">
                    <td>
                        <div v-if="props.item.estado">
                            <span class="greenA--text">Activo</span>
                        </div>
                        <div v-else>
                            <span class="pinkA--text">Inactivo</span>
                        </div>
                    </td>
                </template>

                <template v-slot:item.opciones="{ item }">
                    <v-btn color="blueA" @click="editItem(item)" class="mr-2 mt-1" fab dark small title="Editar usuario">
                       <v-icon color="white" >edit</v-icon>
                    </v-btn>
                    <template v-if="item.estado">
                    <v-btn color="pinkA" @click="activateDeactivate(false,item)" class="mr-2 mt-2 mb-1" fab dark small title="Desactivar uduario">
                       <v-icon color="white" >block</v-icon>
                    </v-btn>
                    </template>
                    <template v-else>
                    <v-btn color="greenA" @click="activateDeactivate(true,item)" class="mr-2 mt-2 mb-1" fab dark small title="Activar uduario">
                       <v-icon color="white" >check</v-icon>
                    </v-btn>
                    </template>
                </template>

                <template v-slot:no-data>
                    <v-btn color="blueA" @click="listUsuarios()">
                    Recargar listado
                    </v-btn>
                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
</template>


<script>
import axios from 'axios';
export default {
    data(){
        return {
            dialog: false,
            search: '',
            usuarios: [],
            headers: [
                { text: 'Nombre',   value:  'nombre',   sortable:true   },
                { text: 'Documento',value:  'documento',sortable:false  },
                { text: 'Rol',      value:  'rol',      sortable:false  },
                { text: 'Teléfono', value:  'telefono', sortable:false  },
                { text: 'Correo',   value:  'correo',   sortable:true   },
                { text: 'Estado',   value:  'estado',   sortable:false  },
                { text: 'Opciones', value:  'opciones', sortable:false  },
            ],
            editedIndex: -1,
            _id:'',
            nombre:'',
            documento:'',
            rol:'',
            telefono:'',
            correo:'',
            rol:'',
            tipos:['Administrador', 'Usuario Regular'],
            valid:0,
            validMessage:[],
            dialogActDeact:false,
            adAction:false,
            adNombre:'',
            adId:'',
            token: this.$store.state.token,
        }
    },

    computed: {
        formTitle () {
        return this.editedIndex === -1 ? 'Crear Usuario' : 'Editar Usuario'
        },
    },

    watch: {
        dialog (val) {
        val || this.close()
        },
        dialogActDeact (val) {
        val || this.closeDelete()
        },
    },

    created () {
        this.listUsuarios()
    },

    methods: {
        validate(){
            this.valid =         0;
            this.validMessage = [];
            if ( this.nombre.length<3 || this.nombre.length>50 ){
                this.validMessage.push('Por favor, el nombre ');
            }
            if ( this.documento.length<5 || this.documento.length>50 ){
                this.validMessage.push('Por favor, revisa el documento.');
            }          
            if ( this.telefono.length<10 || this.telefono.length>10 ){
                this.validMessage.push('Por favor, revisa el teléfono.');
            }
            if ( this.correo.length<3 || this.correo.length>50 ){
                this.validMessage.push('El correo no puede estar vacío.');
            }
            if (!this.validEmail(this.correo)){
                this.validMessage.push('Por favor, revisa el correo.');
            }
            if ( this.validMessage.length){
                this.valid = 1;
            }
            return this.valid;                           

        },

        listUsuarios(){
            let me = this;
            let headers = {headers: {token: this.token}};
            axios.get('api/admin/list', headers)
            .then(function(response){
                me.usuarios    =   response.data.usuarios;
            })
            .catch(function(error){
                console.log(error);
            })
        },

        clear(){
            this._id          ='';
            this.nombre       ='';
            this.documento    ='';
            this.telefono     ='';
            this.correo       ='';
            this.valid        =0;
            this.validMessage =[];
            this.editedIndex  =-1;
        },

        save () {
            let me = this;
            if( this.validate()){
                return;
            }

            if (this.editedIndex > -1) {
                // Editar usuario
                let headers = {headers: {token: this.token}};
                axios.put('api/admin/update',{
                    '_id'       :this._id,
                    'nombre'    :this.nombre,
                    'documento' :this.documento,
                    'telefono'  :this.telefono,
                    'correo'    :this.correo,
                    'rol'       : this.rol,
                    },headers)
                    .then(function (response) {
                        me.clear();
                        me.close();
                        me.listUsuarios();
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            } else {
                let headers = {headers: {token: this.token}};
                // Guardar usuario
                axios.post('api/admin/register',{
                    'nombre'    :this.nombre,
                    'documento' :this.documento,
                    'telefono'  :this.telefono,
                    'correo'    :this.correo,
                    'contrasena':this.documento,
                    'rol'       : this.rol,
                    }, headers)
                    .then(function (response) {
                        me.clear();
                        me.close();
                        me.listUsuarios();
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            }
        
        },

        editItem (item) {
            this._id        = item._id;
            this.nombre     = item.nombre;     
            this.documento  = item.documento;
            this.telefono   = item.telefono;
            this.correo     = item.correo;
            this.rol        = item.rol;
            this.dialog     = true;
            this.editedIndex= 1;
        },

        activateDeactivate (action, item) {
            this.dialogActDeact = true;
            this.adNombre       = item.nombre;
            this.adId           = item._id;
            if (action==true){
                this.adAction = false;
            }else if (action==false){
                this.adAction = true;
            }else{
                this.dialogActDeact = false;
            }
        },

        ActionActDeact (actDeact) {
            let me = this;
            if (actDeact){
                let headers = {headers: {token: this.token}};
                
                // Desactivar admin
                axios.put('api/admin/deactivate?_id='+this.adId,null, headers)
                    .then(function (response) {
                        me.clear();
                        me.close();
                        me.listUsuarios();
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            }else{
                let headers = {headers: {token: this.token}};
                // Activar admin
                axios.put('api/admin/activate?_id='+this.adId,null, headers)
                    .then(function (response) {
                        me.clear();
                        me.close();
                        me.listUsuarios();
                    })
                    .catch(function (error) {
                        console.log(error);
                    })                

            }


        },

        close () {
            this.clear();
            this.dialog = false;
            this.dialogActDeact = false;
            this.editedIndex = -1
        },

        closeDelete () {
        this.dialogActDeact = false
        this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
        })
        },

        validEmail (correo) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(correo);
        }       

    },
}
</script>